<template>
    <o-data-lookup :data-object="dsIntervalLkpDataSource">
        <template #target="{ target }">
            <input :ref="target" v-bind="$attrs" :value="inputValue"/>
        </template>
        <o-column field="IntervalName" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>
import { getOrCreateDataObject } from 'o365.vue.ts';
    const props = defineProps({
        inputValue: String
    });

    const dsIntervalLkpDataSource = getOrCreateDataObject({
        id: 'dsIntervalLkpDatasourceFromSiteSetup',
        viewName: 'aviw_Status_IntervalsTLookups',
        maxRecords: 100,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "Interval_ID", type: "number" },
            { name: "IntervalName", type: "number" },
            { name: "SortOrder ", type: "number", sortOrder: 1, sortDirection: "asc" }
        ]
    });
</script>